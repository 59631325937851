import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppLayout } from "../../components/layouts/AppLayout";
import { LandingLayout } from "../../components/layouts/LandingLayout";
import { SharedItemLayout } from "../../pages/public/sharing/SharedItemLayout";
import { Loading } from "../../components/Loading";
import { NavBar } from "../../components/navigation/NavBar";
import { Footer } from "../../components/layouts/Footer";

const Workbench = lazy(() => import("../../pages/application/Workbench"));
const Dashboard = lazy(() => import("../../pages/application/Dashboard"));
const Home = lazy(() => import("../../pages/public/Home"));
const Error = lazy(() => import("../../pages/public/Error"));
const Login = lazy(() => import("../../pages/public/Login"));
const Logout = lazy(() => import("../../pages/public/Logout"));
const NotFound = lazy(() => import("../../pages/public/NotFound"));
const ProfileEditor = lazy(
  () => import("../../pages/application/ProfileEditor"),
);
const Contact = lazy(() => import("../../pages/public/Contact"));
const SyllabusEditor = lazy(
  () => import("../../pages/application/SyllabusEditor"),
);
const Status = lazy(() => import("../../pages/public/Status"));
const Studio = lazy(() => import("../../pages/application/Studio"));
const OutcomesExplorer = lazy(
  () => import("../../pages/application/OutcomesExplorer"),
);
const SharingAndPrivacy = lazy(
  () => import("../../pages/application/SharingAndPrivacy"),
);
const Content = lazy(() => import("../../pages/application/Content"));
const ContentEditor = lazy(
  () => import("../../pages/application/ContentEditor"),
);
const Privacy = lazy(() => import("../../pages/public/Privacy"));
const Terms = lazy(() => import("../../pages/public/Terms"));

export const AppRoutes = (): JSX.Element => {
  return (
    <Suspense
      fallback={
        <>
          <NavBar navItems={[]} />
          <div className="w-full h-screen flex justify-center items-center bg-transparent">
            <Loading size="md" />
          </div>
          <Footer />
        </>
      }
    >
      <Routes>
        {/* Public routes */}
        <Route path="login" element={<Login />} />
        <Route path="error" element={<Error />} />
        <Route path="logout" element={<Logout />} />
        <Route path="status" element={<Status />} />

        {/* Shared item */}
        <Route path="shared/:ownerId/:itemId" element={<SharedItemLayout />} />

        {/* Home Layout */}
        <Route path="/" element={<LandingLayout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />

          <Route path="contact" element={<Contact />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
        </Route>

        {/* Application */}
        <Route path="/" element={<AppLayout />}>
          <Route path="workbench" element={<Workbench />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="profile" element={<ProfileEditor />} />
          <Route path="sharing" element={<SharingAndPrivacy />} />

          {/* Studio */}
          <Route path="studio" element={<Studio />} />
          <Route path="studio/:tab" element={<Studio />} />

          {/* Content */}
          <Route path="content" element={<Content />} />
          <Route path="content/:id" element={<Content />} />
          <Route path="content/editor/:id" element={<ContentEditor />} />
          <Route path="content/editor/:id/:tab" element={<ContentEditor />} />
          <Route
            path="content/editor/:id/review/:sliceId"
            element={<ContentEditor />}
          />

          <Route
            path="syllabi"
            element={<Navigate to="/outcomes/create" replace />}
          />
          <Route path="syllabi/editor/:id" element={<SyllabusEditor />} />
          <Route path="syllabi/editor/:id/:tab" element={<SyllabusEditor />} />

          {/* Outcomes */}
          <Route path="outcomes" element={<OutcomesExplorer />} />
          <Route path="outcomes/:tab" element={<OutcomesExplorer />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
