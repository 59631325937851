import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { SYLLABYTE_LOGO_LIGHT_URL } from "../../util/Constants";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

interface FooterProperties {
  variant?: "thin" | "thick";
}

export function Footer({ variant = "thick" }: FooterProperties): JSX.Element {
  const footerNavItems = [
    { path: "/?fl=1", label: "Home", end: true },
    { path: "/contact", label: "Contact" },
    { path: "/privacy", label: "Privacy" },
    { path: "/terms", label: "Terms of Service" },
  ];

  const statusLink = `https://${process.env.REACT_APP_SYLLABYTE_STATUSPAGE_ID}.statuspage.io`;

  const socialNavItems = [
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/syllabyteai",
      icon: (properties: React.SVGProps<SVGSVGElement>) => (
        <svg
          fill="currentColor"
          data-icon="linkedin-in"
          viewBox="0 0 448 512"
          {...properties}
        >
          <path
            fill="currentColor"
            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
          ></path>
        </svg>
      ),
    },
  ];

  if (variant === "thin") {
    return (
      <footer className="bg-syllabyte-navy py-4 w-full">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            {/* Logo and Copyright */}
            <div className="flex items-center space-x-2">
              <a
                href="https://www.syllabyte.ai"
                target="_blank"
                rel="noreferrer noopener nofollow"
                className="flex items-center"
              >
                <img
                  loading="lazy"
                  className="h-8"
                  src={SYLLABYTE_LOGO_LIGHT_URL}
                  alt="Syllabyte.ai"
                />
                <span className="ml-4 text-sm text-gray-400">
                  ©2025 SyllabyteAI, Inc.
                </span>
              </a>
            </div>

            {/* Navigation Links */}
            <nav className="flex items-center space-x-6" aria-label="Footer">
              {footerNavItems.map(item => (
                <NavLink
                  key={item.label}
                  to={item.path}
                  className={({ isActive }) =>
                    clsx(
                      "text-sm text-white hover:text-gray-300",
                      isActive ? "border-b border-gray-400" : "",
                    )
                  }
                >
                  {item.label}
                </NavLink>
              ))}

              {/* Social and Status Links */}
              {socialNavItems.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  className="text-gray-400 hover:text-gray-300"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-5 w-5" aria-hidden="true" />
                </a>
              ))}
              <a
                className="text-sm text-gray-400 hover:text-gray-300 flex items-center"
                target="_blank"
                rel="noreferrer noopener nofollow"
                title="Status Page"
                href={statusLink}
              >
                <ArrowTopRightOnSquareIcon className="h-4 w-4 mr-1" />
                Status
              </a>
            </nav>
          </div>
        </div>
      </footer>
    );
  }

  return (
    <footer className="bg-syllabyte-navy pt-12">
      <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {footerNavItems.map(item => (
            <div key={item.label} className="px-5 py-2">
              <NavLink
                to={item.path}
                className={({ isActive }) => {
                  return clsx(
                    "text-base text-white hover:text-gray-300 hover:border-b hover:border-gray-400",
                    isActive ? "border-b border-gray-400" : "",
                  );
                }}
              >
                {item.label}
              </NavLink>
            </div>
          ))}
        </nav>
        <div className="mt-12 xs:pt-0 md:pt-12 md:flex xs:text-center md:text-left xs:items-center md:justify-between">
          <div className="text-center">
            <a
              href="https://www.syllabyte.ai"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              <img
                loading="lazy"
                className="h-12 mx-auto"
                src={SYLLABYTE_LOGO_LIGHT_URL}
                alt="Syllabyte.ai"
              />
              <p className="mt-8 text-gray-400 text-base xs:mb-4 md:mb-0 md:mt-0 md:order-1">
                ©2025 SyllabyteAI, Inc. All rights reserved.
              </p>
            </a>
          </div>
          <div className="flex xs:pt-4 md:pt-0 items-center justify-center space-x-6 md:order-2">
            {socialNavItems.map(item => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noreferrer noopener nofollow"
                className="text-gray-400 hover:text-gray-300 hover:border-b hover:border-gray-300"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
            <a
              className="text-gray-400 hover:text-gray-300 border-b border-gray-400 hover:border-gray-300"
              target="_blank"
              rel="noreferrer noopener nofollow"
              title="Status Page"
              href={statusLink}
            >
              <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />
              Status
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
