import { Component } from "react";
import { Glossary } from "./Glossary";
import { Outcome } from "./Outcome";
import { PedagogicalMethodology } from "./PedagogicalMethodology";
import { Presentation } from "./Presentation";
import { Quiz } from "./Quiz";
import { Resource } from "./Resource";
import { TfcldTaxonomy } from "./TfcldTaxonomy";
import { WefTaxonomy } from "./WefTaxonomy";
import { Syllabus } from "./Syllabus";
import { isAudioVideoFile, trimTimespan } from "../util/Helpers";
import { ContentSlice } from "./ContentSlice";

export interface Content {
  // Database Id Params
  ownerId?: string;
  id?: string;

  // Core Metadata
  title?: string;
  description?: string;
  tags?: string[];
  languages?: string[];
  audience?: string;

  // Academic Metadata
  authors?: string[];
  publisher?: string;
  publishingYear?: string;
  reference?: string;
  citation?: string;
  isbn?: string;
  doi?: string;

  // File Properties
  md5Hash?: string;
  fileName?: string;
  fileExtension?: string;
  contentType?: string;
  byteSize?: number;
  storeUrl?: string;
  lastUpdated?: Date;

  // Access Control
  enabled?: boolean;
  shareWithOrganisation?: boolean;
  joinKey?: string;
  joinEnabled?: boolean;

  // Learning Content
  outcomesInput?: string;
  outcomes?: Outcome[];
  enhancementEnabled?: boolean;
  methodology?: PedagogicalMethodology;
  suggestions?: Component[];
  components?: Component[];
  deliveryInstructions?: string;

  // Associated Learning Materials
  quiz?: Quiz;
  glossary?: Glossary;
  presentation?: Presentation;

  // External Resources
  boclipsVideoResources?: Resource[];
  wileyOnlineResources?: Resource[];
  bingResources?: Resource[];
  wikipediaResources?: Resource[];

  // Taxonomies and Evaluation
  tfcldEvaluation?: TfcldTaxonomy;
  tfcldRecommendations?: TfcldTaxonomy;
  wefEvaluation?: WefTaxonomy;
  wefRecommendations?: WefTaxonomy;

  // Page/Document Processing
  pageIndexOffset?: number;
  pageIndexOffsetConfidence?: number;
  pageIndexOffsetJustification?: string;
  firstFewPages?: string;

  // Media Properties
  durationSeconds?: number;
  height?: number;
  width?: number;
  videoIndexerId?: string;

  // Media Analysis Results
  /*
  transcript?: Transcript[];
  ocr?: Ocr[];
  topics?: Topic[];
  namedPeople?: NamedPeople[];
  audioEffects?: AudioEffect[];
  detectedObjects?: DetectedObject[];
  sentiments?: Sentiment[];
  emotions?: Emotion[];
  speakers?: Speaker[];
  labels?: Label[];
  scenes?: Scene[];
  shots?: Shot[];
  brands?: Brand[];
  */

  // Status Tracking
  task?: string;
  state?: string;
  step?: number;
  progressPercent?: number;
  message?: string;
}

export interface ContentTaskRequest {
  slices?: string[];
  task: string;
  syllabi?: Syllabus[];
  outputLanguage?: string;
}

export interface ContentQuery {
  searchText?: string;
  pageSize?: number;
  continuationToken?: string;
}

export interface ContentQueryResponse {
  content: Content[];
  continuationToken?: string;
}

// Helper functions
export const getTaskLabel = (task?: string): string => {
  if (!task) {
    return "Uploaded";
  }
  const taskMap: Record<string, string> = {
    upload: "Uploaded",
    spelling: "Checking Spelling & Grammar",
    align: "Aligning Standards",
    "extract-outcomes": "Extracting Outcomes",
  };
  return taskMap[task] || "Processing";
};

export const getStateLabel = (state: string): string => {
  const stateMap: Record<string, string> = {
    ready: "Queued",
    inProgress: "In Progress",
    complete: "Complete",
    failed: "Failed - Retrying",
    broken: "Error",
  };
  return stateMap[state] || state;
};

export const calculateActualPageNumber = (
  sliceData: ContentSlice,
  contentData: Content,
): number => {
  return (sliceData.page ?? 0) + (contentData.pageIndexOffset ?? 0);
};

export const showPage = (
  sliceData: ContentSlice,
  contentData: Content,
): string => {
  // Ensure this only works for PDF files
  if (!contentData.contentType?.includes("pdf")) {
    return "";
  }

  return `Page ${calculateActualPageNumber(sliceData, contentData)}`;
};

export interface ContentInterrogation {
  question: string;
}

export const showTranscriptData = (
  sliceData: ContentSlice,
  contentData: Content,
): string => {
  if (!isAudioVideoFile(contentData.fileExtension)) {
    return "";
  }

  if (!sliceData) {
    return "";
  }

  if (!contentData) {
    return "";
  }

  if (!sliceData.start || !sliceData.end) {
    return "";
  }

  return ` | ${trimTimespan(sliceData.start)} - ${trimTimespan(sliceData.end)}${sliceData.speakerName ? ` (${sliceData.speakerName})` : ""}`;
};

export interface FileUploadRequest {
  file?: File;
}
