import { SharedItem } from "../../../types/platform/SharedItem";
import {
  generateBreadcrumb,
  Breadcrumbs,
} from "../../../components/Breadcrumbs";
import { MetaTags } from "../../../components/layouts/MetaTags";
import { ProfileBadge } from "../../../components/profile/ProfileBadge";
import { ContentCard } from "../../../features/content/ContentCard";
import { useEffect } from "react";

export interface SharedContentViewerProperties {
  accessKey?: string;
  goalId?: string;
  sharedItem: SharedItem;
}

export function SharedContentViewer(
  properties: SharedContentViewerProperties,
): JSX.Element {
  const { ownerId, ownerEmail, ownerName, id, content, downloadUrl } =
    properties.sharedItem;

  useEffect(() => {
    console.log("upload properties::", properties);
  }, []);

  if (content == undefined) {
    throw new Error("upload must be provided");
  }

  const titleTag = `Shared Upload: ${content.title}`;
  const descriptionTag = `Shared Content from ${ownerName}`;

  const breadcrumbs = generateBreadcrumb(
    generateBreadcrumb([], "Sharing & Privacy", "/sharing"),
    `Content: "${content?.title}" from ${ownerName}` || "",
    `/shared/content/${ownerId}/${id}`,
    `${ownerId}/${id}`,
  );

  return (
    <main>
      <MetaTags title={titleTag} description={descriptionTag} />

      {breadcrumbs.length > 0 && (
        <Breadcrumbs
          basePath="/dashboard"
          breadcrumbs={breadcrumbs}
        ></Breadcrumbs>
      )}

      <div className="max-w-6xl w-full mx-auto xs:pt-8 sm:pt-12 xs:pb-4 sm:pb-8 xs:px-4 sm:px-8">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <ProfileBadge id={ownerId} name={ownerName} email={ownerEmail} />
          </div>
          <div className="ml-4 mt-4 flex-shrink-0 flex"></div>
        </div>

        <hr className="mt-6 mb-6" />

        <ContentCard content={content}></ContentCard>
        <p>{content.description}</p>
        <p>{downloadUrl}</p>
      </div>
    </main>
  );
}
