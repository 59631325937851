import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router";
import { NavItem } from "../../types/platform/NavItem";
import { Badge } from "../badges/Badge";

export const NavLinkItem: React.FC<{
  item: NavItem;
  onClick?: () => void;
  className: string;
}> = React.memo(({ item, onClick, className }) => (
  <NavLink
    to={item.path || ""}
    onClick={onClick}
    className={({ isActive }) =>
      clsx(
        className,
        isActive
          ? "border-syllabyte-navy text-syllabyte-navy dark:border-syllabyte-navy dark:text-syllabyte-navy"
          : "text-gray-700 dark:text-gray-300 hover:text-syllabyte-navy dark:hover:text-syllabyte-navy",
      )
    }
  >
    {item.label}
    {item.new && (
      <Badge
        text="New"
        className="ml-1 mb-1"
        backgroundColor="bg-green-500 dark:bg-green-600"
        foregroundColor="text-white"
      />
    )}
  </NavLink>
));

NavLinkItem.displayName = "NavLinkItem";
