export const BOOKING_FORM_URL =
  "https://outlook.office365.com/book/Syllabyteai1@syllabyte.ai/";

export const SYLLABYTE_LOGO_DARK_URL =
  "https://assets.syllabyte.ai/images/syllabyte-horizontal-dark.svg";

export const SYLLABYTE_LOGO_LIGHT_URL =
  "https://assets.syllabyte.ai/images/syllabyte-horizontal-light.svg";

export const SYLLABYTE_LOGO_SQUARE =
  "https://assets.syllabyte.ai/images/syllabyte-ai-logo.png";

export const PARTNER_LOGO_VHL =
  "https://assets.syllabyte.ai/images/partner-logos/vista-higher-learning-logo.png";

export const PARTNER_LOGO_COPYRIGHTAGENCYAUSTRALIA =
  "https://assets.syllabyte.ai/images/partner-logos/copyright-agency-australia-logo.png";

export const PARTNER_LOGO_EDX =
  "https://assets.syllabyte.ai/images/partner-logos/edx-logo.png";

export const PARTNER_LOGO_TEACHOLOGYAI =
  "https://assets.syllabyte.ai/images/partner-logos/teachology-horizontal-logo.png";
